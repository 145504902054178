import axios from "axios";
import { ONBOARDING } from "./constant";
import { getHeaders } from "./headers";

//{params}, {body}
export const sendOTP = async ({ mobile }) => {
  return await axios.post(`${ONBOARDING}/otp/send`, null, {
    params: {
      mobile,
    },
    headers: getHeaders(true),
  });
};

export const verifyOTP = async ({ mobile }, body) => {
  return await axios.post(`${ONBOARDING}/otp/verify`, body, {
    params: { mobile },
    headers: getHeaders(),
  });
};
