import axios from "axios";
import { SCHEMES_BOOK } from "./constant";
import { getHeaders } from "./headers";

export const getPriceList = async ({ brandId, productIds }) => {
  return await axios.get(`${SCHEMES_BOOK}/user-price`, {
    params: {
      brandId,
      userId: localStorage.getItem("userId"),
      productId: productIds.join(","),
    },
    headers: getHeaders(true),
  });
};

export const addPrice = async ({ formPayload }) => {
  return await axios.post(`${SCHEMES_BOOK}/user-price`, formPayload, {
    headers: getHeaders(true),
  });
};

export const editPrice = async ({ formPayload, id }) => {
  return await axios.put(`${SCHEMES_BOOK}/user-price/${id}`, formPayload, {
    headers: getHeaders(true),
  });
};
