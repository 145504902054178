import { Box, CircularProgress, Container } from "@mui/material";
import React, { useRef, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import login from "../assets/img/login.svg";
// import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { sendOTP, verifyOTP } from "../apis/login";
import { userValidate } from "../apis/users";
import { LoginContext } from "..";
import QRCode from "react-qr-code";

const Login = () => {
  const { setLoggedIn } = useContext(LoginContext);

  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();
  const [verifyPayload, setVerifyPayload] = useState({
    verify_id: "",
    otp: "",
  });
  const [otpSent, setOtpSent] = useState(false);
  const [qrLogin, setQrLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSendOTP = async () => {
    if (phoneNumber.length === 10) {
      setLoading(true);
      try {
        const otpResp = await sendOTP({
          mobile: phoneNumber.startsWith("+91")
            ? phoneNumber
            : "+91" + phoneNumber,
        });
        setLoading(false);
        setOtpSent(true);
        console.log(otpResp.data.response.data.verify_id);
        setVerifyPayload((p) => {
          const payload = {
            ...p,
            verify_id: otpResp.data.response.data.verify_id,
          };
          return payload;
        });
      } catch (error) {
        setLoading(false);
        toast("Error sending OTP.", { type: "error" });
      }
    } else {
      toast("Enter correct Phone Number", { type: "error" });
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("userId") &&
      localStorage.getItem("state")
    ) {
      setLoggedIn(true);
      navigate("/user");
    }
  }, []);

  return (
    <>
      {loading && (
        <div className="absolute flex bg-[#00000030] justify-center items-center w-full h-[100vh]">
          <CircularProgress color="inherit" />
        </div>
      )}

      {qrLogin ? (
        <QRLoginContainer />
      ) : otpSent ? (
        <VerifyOTPContainer
          phoneNumber={phoneNumber}
          sendOTP={handleSendOTP}
          verifyPayload={verifyPayload}
        />
      ) : (
        <SendOTPContainer
          handleSendOTP={handleSendOTP}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
      )}
    </>
  );
};

export default Login;

const SendOTPContainer = ({ phoneNumber, setPhoneNumber, handleSendOTP }) => {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <img src={login} alt="" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}>
        <Typography component="h1" variant="h5">
          Login with Phone Number
        </Typography>
        <Box sx={{ mt: 1, width: "100%" }}>
          <TextField
            fullWidth
            margin="normal"
            value={phoneNumber}
            onChange={(e) =>
              e.target.value.length <= 10 && setPhoneNumber(e.target.value)
            }
            required
            placeholder="Enter Phone Number"
            id="mobile"
            label="Mobile"
            name="mobile"
            autoFocus
          />
          <Button
            onClick={async () => {
              await handleSendOTP();
            }}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}>
            Next
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

const QRLoginContainer = ({}) => {
  return (
    <Container sx={{ alignItems: "center" }} component="main" maxWidth="sm">
      <Box
        display="flex"
        flexDirection={"column"}
        justifyContent="flex-start"
        alignItems="center"
        minHeight="100vh">
        <Typography component="h1" variant="h5">
          Scan the QR from mobile application.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <QRCode value="hey" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}>
          <Box sx={{ mt: 1, width: "100%" }}>
            <Button
              onClick={async () => {}}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}>
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

const VerifyOTPContainer = ({ phoneNumber, sendOTP, verifyPayload }) => {
  const [otp, setOtp] = useState({ 0: "", 1: "", 2: "", 3: "", 4: "", 5: "" });
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const { setLoggedIn } = useContext(LoginContext);
  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);
  const handleVerifyOTP = async (payload) => {
    const joinedOTP = Object.values(otp).join("");
    try {
      const verifyResp = await verifyOTP(
        { mobile: phoneNumber },
        { verify_id: payload, otp: joinedOTP }
      );
      localStorage.setItem("token", verifyResp.data.token);
      const userValidateResp = await userValidate();
      console.log(userValidateResp.data);
      localStorage.setItem("userId", userValidateResp.data.data.user.id);
      localStorage.setItem(
        "state",
        userValidateResp.data.data.user?.business?.address?.state.name
      );
      setLoggedIn(true);
      navigate("/user");
    } catch (error) {
      console.log(error);
      toast("Error validating OTP.", { type: "error" });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Typography component="h1" variant="h5">
          Verify Phone Number
        </Typography>
        <Typography sx={{ margin: 1, textAlign: "center" }}>
          Enter the 6-Digit code sent to you at +91{phoneNumber}
        </Typography>
        <div className="flex justify-center items-center w-full h-14">
          {Object.keys(otp).map((key, i) => (
            <div
              key={i}
              className="flex mx-3 h-10  border-2 rounded  aspect-square ">
              <input
                onFocus={() => setOtp({ ...otp, [Number(i)]: "" })}
                value={otp[key]}
                ref={(ref) => (inputRefs.current[i] = ref)}
                onChange={(e) => {
                  if (i <= 4) {
                    inputRefs.current[Number(i) + 1].focus();
                  }
                  setOtp({ ...otp, [i]: e.target.value });
                }}
                className=" text-center w-full h-full text-xl "
                maxLength={1}
              />
            </div>
          ))}
        </div>
        <Button
          onClick={async () => {
            await handleVerifyOTP(verifyPayload.verify_id);
          }}
          type="submit"
          fullWidth
          size="large"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}>
          Continue
        </Button>
      </Box>
      <div className="flex w-full justify-evenly">
        <div className="text-xs">Didn't receive code?</div>
        <div
          onClick={async () => {
            await sendOTP({ mobile: phoneNumber });
          }}
          className="text-xs text-blue-600 cursor-pointer">
          Resend
        </div>
      </div>
    </Container>
  );
};
