import axios from "axios";
import { SCHEMES_BOOK } from "./constant";
import { getHeaders } from "./headers";

export const getProductsListByFilter = async ({page, size, brandId }) => {
  return await axios.get(`${SCHEMES_BOOK}/product/filter`, {
    params:{
        page,
        size,
        brandId,
    },
    headers: getHeaders(true),
  });
};
