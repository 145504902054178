import { X_API_KEY } from "./constant";

const getHeaders = () => {
  return {
    "x-api-key": X_API_KEY,
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
};

export { getHeaders };
