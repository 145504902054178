import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { getBrands } from "../apis/brands";
import { toast } from "react-toastify";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { addPrice, editPrice, getPriceList } from "../apis/priceList";
import TextField from "@mui/material/TextField";
import { getProductsListByFilter } from "../apis/products";
import TableHeader from "../components/Table/TableHeader";
import TableElement from "../components/Table/TableElement";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Pagination from "@mui/material/Pagination";

function PriceList() {
  const color = "light";

  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const [totalPages, setTotalPages] = useState();
  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchSize, setFetchSize] = useState(25);

  const getBrandsList = async () => {
    try {
      const response = await getBrands({ page: 0, size: 99 });
      setBrands(response.data.data);
      if (response.data.data.length) {
        setSelectedBrand(response.data.data[0].id);
      }
    } catch (error) {
      toast("Error Fetching Brands", {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
    return currentPage;
  };

  const getProductsList = async (brandId, page, size) => {
    try {
      const response = await getProductsListByFilter({
        page: page,
        size: size,
        brandId,
      });

      if (response.data.data.length) {
        try {
          const priceListReps = await getPriceList({
            brandId: response.data.data[0].brand.id,
            productIds: response.data.data.map((p) => p.id),
          });
          setTotalPages(response.data.pages);
          setTotalElements(response.data.totalElements);
          setProducts(
            response.data.data.map((p) => {
              const price = p.prices.filter(
                (p) => p?.group?.name === localStorage.getItem("state")
              )[0];

              const userPrice = priceListReps.data.data.productPrices.filter(
                (pp) => pp.product.id === p.id
              )[0];

              const newProduct = {
                ...p,
                price: {
                  dp: price?.dp,
                  mop: price?.mop,
                  mrp: price?.mrp,
                  wholesale: userPrice?.price?.wholesale,
                  id: userPrice?.price?.id,
                  retail: userPrice?.price?.retail,
                  remark: userPrice?.price?.remark,
                },
              };
              return newProduct;
            })
          );
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      toast("Error Fetching Products", {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    getBrandsList();
  }, []);

  useEffect(() => {
    getProductsList(selectedBrand, currentPage - 1, fetchSize);
  }, [selectedBrand, currentPage, fetchSize]);

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 mt-4 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full items-center overflow-x-auto p-8">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Brand
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  variant="standard"
                  value={selectedBrand ? selectedBrand : ""}
                  label="Select Brand"
                  onChange={(e) => {
                    setSelectedBrand(e.target.value);
                  }}>
                  {brands.map((b, i) => (
                    <MenuItem key={i} value={b.id}>
                      {b.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </div>

      {products && products.length !== 0 ? (
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
            (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
          }>
          <div className="block w-full overflow-x-auto p-8">
            <div className="container mx-auto my-3">
              {" "}
              <div className="flex flex-wrap items-center justify-between mb-2">
                {" "}
                <div className="w-full sm:w-auto mb-2 sm:mb-0 sm:mr-2">
                  {" "}
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Size</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      value={fetchSize}
                      label="Size"
                      onChange={(e) => {
                        setCurrentPage(1);
                        setFetchSize(e.target.value);
                      }}>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="w-full sm:w-auto mb-2 sm:mb-0 sm:mr-2 ">
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    color="primary"
                    onChange={handlePageChange}
                    defaultPage={currentPage}
                    siblingCount={0}
                  />
                </div>
                <div className="w-full sm:w-auto">
                  <div className="text-sm font-medium">
                    Total Products: {totalElements}
                  </div>
                </div>
              </div>
            </div>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr key={"header"} className="divide-x divide-gray-200 ">
                  <TableHeader heading="" />
                  <TableHeader heading="Name" />
                  <TableHeader heading="DP" />
                  <TableHeader heading="MOP" />
                  <TableHeader heading="MRP" />
                  <TableHeader heading="Wholesale" />
                  <TableHeader heading="Retail" />
                  <TableHeader heading="REmark" />
                  <TableHeader heading="" />
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {products?.map((p, i) => {
                  return (
                    <ProductPriceRow
                      key={i}
                      p={p}
                      i={i}
                      selectedBrandId={selectedBrand}
                      getProductsList={getProductsList}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

const AddOrEditPriceDialog = ({
  row,
  openDialog,
  setOpenDialog,
  selectedBrandId,
  formPayload,
  setFormPayload,
  getProductsList,
}) => {
  const addPriceDetails = async () => {
    try {
      await addPrice({ formPayload });
      toast("Price Added Successfully", {
        type: "success",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      setOpenDialog(false);
      getProductsList(selectedBrandId);
    } catch (error) {
      toast("Error Saving Changes", {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };

  const editPriceDetails = async () => {
    const id = row?.price?.id;
    try {
      await editPrice({ formPayload, id });
      toast("Changes Saved Successfully", {
        type: "success",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      setOpenDialog(false);
      getProductsList(selectedBrandId);
    } catch (error) {
      toast("Error Saving Changes", {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setFormPayload({
            ...formPayload,
            wholesale: null,
            retail: null,
            remark: "",
          });
        }}>
        <DialogTitle>
          {row?.primaryName +
            " " +
            (row.secondaryName ? row.secondaryName : "")}
        </DialogTitle>
        <DialogContent className="text-center">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={true}
                id="standard-basic"
                sx={{ m: 2 }}
                type="number"
                label="DP"
                name="DP"
                variant="standard"
                value={row?.price?.dp}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={true}
                id="standard-basic"
                sx={{ m: 2 }}
                type="number"
                label="MOP"
                name="mop"
                variant="standard"
                value={row?.price?.mop}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={true}
                id="standard-basic"
                sx={{ m: 2 }}
                type="text"
                label="MRP"
                name="mrp"
                variant="standard"
                value={row?.price?.mrp}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                id="standard-basic"
                sx={{ m: 2 }}
                type="number"
                label="Wholesale"
                name="wholesale"
                variant="standard"
                value={formPayload?.wholesale}
                onChange={(e) => {
                  setFormPayload({
                    ...formPayload,
                    productId: row.id,
                    wholesale: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="standard-basic"
                sx={{ m: 2 }}
                type="number"
                label="Retail"
                name="retail"
                variant="standard"
                value={formPayload?.retail}
                onChange={(e) => {
                  setFormPayload({
                    ...formPayload,
                    productId: row.id,
                    retail: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="standard-basic"
                sx={{ m: 2 }}
                type="text"
                label="Remark"
                name="remark"
                variant="standard"
                value={formPayload?.remark}
                onChange={(e) => {
                  setFormPayload({
                    ...formPayload,
                    productId: row.id,
                    remark: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {row?.price?.id ? (
            <Button onClick={() => editPriceDetails()}>Save Changes</Button>
          ) : (
            <Button onClick={() => addPriceDetails()}>Save</Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PriceList;

const ProductPriceRow = ({ p, i, selectedBrandId, getProductsList }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const [formPayload, setFormPayload] = useState({
    userId: localStorage.getItem("userId"),
    productId: "",
    brandId: selectedBrandId,
    wholesale: null,
    retail: null,
    remark: "",
  });

  return (
    <>
      <AddOrEditPriceDialog
        row={p}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        formPayload={formPayload}
        setFormPayload={setFormPayload}
        getProductsList={getProductsList}
      />

      <tr key={i} className="divide-x divide-gray-200 hover:bg-slate-100">
        <TableElement value={i + 1} />
        <TableElement
          value={
            p?.primaryName + " " + (p.secondaryName ? p.secondaryName : "")
          }
        />
        <TableElement value={p?.price?.dp ? p?.price?.dp : "-"} />
        <TableElement value={p?.price?.mop ? p?.price?.mop : "-"} />
        <TableElement value={p?.price?.mrp ? p?.price?.mrp : "-"} />
        <TableElement value={p?.price?.wholesale} />
        <TableElement value={p?.price?.retail} />
        <TableElement value={p?.price?.remark} />

        <TableElement
          value={
            p?.price?.id ? (
              <span
                className="cursor-pointer text-sky-500 hover:text-sky-950 font-bold gr "
                onClick={() => {
                  setOpenDialog(true);
                  setFormPayload({
                    ...formPayload,
                    wholesale: p?.price?.wholesale,
                    retail: p?.price?.retail,
                    remark: p?.price?.remark,
                  });
                }}>
                EDIT
              </span>
            ) : (
              <span
                className="cursor-pointer text-sky-500 hover:text-sky-950 font-bold gr "
                onClick={() => {
                  setOpenDialog(true);
                }}>
                ADD
              </span>
            )
          }
          type="element"
        />
      </tr>
    </>
  );
};
