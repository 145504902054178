import React, { useEffect, createContext, useState } from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/index.css";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { CircularProgress, createTheme, ThemeProvider } from "@mui/material";
import Login from "./view/Login";
import { ToastContainer, toast } from "react-toastify";
import Admin from "./layouts/Admin";
import PriceList from "./view/PriceList";
import "@fortawesome/fontawesome-free/css/all.min.css";
import queryString from "query-string";
import { userValidate } from "./apis/users";

export const LoginContext = createContext(null);

const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

const PageNotFound = ({ loggedIn }) => {
  return loggedIn ? <Navigate to="/user" /> : <Navigate to="/login" />;
};

const Router = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);

  const { token } = queryString.parse(location.search);
  useEffect(() => {
    const handleValidateToken = async () => {
      try {
        if (token) {
          localStorage.setItem("token", token);
        }
        const userValidateResp = await userValidate();
        localStorage.setItem("userId", userValidateResp.data.data.user.id);
        localStorage.setItem(
          "state",
          userValidateResp.data.data.user?.business?.address?.state.name
        );
        setLoggedIn(true);
        navigate(location.pathname);
      } catch (error) {
        setLoggedIn(false);
        navigate("/login");
        console.log(error.response);
        if (token) {
          toast("Error validating token.", { type: "error" });
        }
      }
    };

    handleValidateToken();
  }, []);

  return (
    <LoginContext.Provider value={{ loggedIn, setLoggedIn }}>
      <Routes>
        {loggedIn ? (
          <Route path="user" element={<Admin />}>
            <Route path="price-list" element={<PriceList />} />
          </Route>
        ) : (
          <>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/loading"
              element={
                <div className="absolute flex bg-[#00000030] justify-center items-center w-full h-[100vh]">
                  <CircularProgress color="inherit" />
                </div>
              }
            />
          </>
        )}
        <Route path="*" element={<PageNotFound loggedIn={loggedIn} />} />
      </Routes>
    </LoginContext.Provider>
  );
};

root.render(
  <ThemeProvider theme={theme}>
    <ToastContainer />
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </ThemeProvider>
);
