import axios from "axios";
import { SCHEMES_BOOK } from "./constant";
import { getHeaders } from "./headers";

export const getBrands =  async({page, size}) =>{
    return await axios.get(`${SCHEMES_BOOK}/brand/filter`, {
        params:{
            page,
            size
        },
        headers:getHeaders(true),
    })
}