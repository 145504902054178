import React from "react";

export default function HeaderStats() {
  return (
    <>
      {/* Header */}
      <div className="relative bg-lightBlue-600 md:pt-32 pb-12 pt-12">
      </div>
    </>
  );
}
